<template lang="pug">
transition(name="modal-fade")
  .modalOverlay.modalProblem.visible.s-swatch-primary(role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription")
    a.modalClose.icon-close-fill.s-position--topRight.s-margin--xs.s-text-size--5xl-baseline--single(href="javascript:void()" @click="close" aria-label="Close modal")
    slot(name="body")
      | Modal
</template>
<script>
export default {
  name: 'Modal',
  methods: {
    close () {
      this.showModalProblem(false)
      document.querySelector('body').classList.remove('s-overflow--hidden')
    }
  }
}
</script>
