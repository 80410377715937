<template lang="pug">
transition(name="modal-fade")
  .modalOverlay.visible.videoScreen.s-swatch-primary.s-padding--xxl(role='dialog')
    a.icon-close-fill.s-position--topRight.s-margin--xs.s-text-size--5xl-baseline--single(href="javascript:void()" @click="closeModalVideo" aria-label="Close modal")
    .videoAspect
      YoutubeVue3.s-position--center(ref="youtube" :videoid="play.video_id" autoplay="0")
</template>
<script>
export default {
  name: 'modalVideo',
  data () {
    return {
      play: { video_id: 'huQTmlfhC_8', loop: 0 }
    }
  },
  mounted () {
    this.$refs.youtube.player.playVideo()
  },
  methods: {
    closeModalVideo () {
      this.$refs.youtube.player.pauseVideo()
      this.showModalVideo(false)
      document.querySelector('body').classList.remove('s-overflow--hidden')
    }
  }
}
</script>
