<template>
  <router-view/>
  <cookieBanner/>
</template>
<script>
import cookieBanner from '@/components/cookieBanner.vue'
export default {
  components: {
    cookieBanner
  },
  mounted () {
    this.parseUtms()
  },
  methods: {
    parseUtms () {
      const urlSearchParams = new URLSearchParams(window.location.search)
      const params = Object.fromEntries(urlSearchParams.entries())
      if (params.utm_source) {
        window.localStorage.setItem('utm_source', params.utm_source || '')
        window.localStorage.setItem('utm_medium', params.utm_medium || '')
        window.localStorage.setItem('utm_content', params.utm_content || '')
        window.localStorage.setItem('utm_term', params.utm_term || '')
      }
      window.localStorage.setItem('referrer', document.referrer)
    }
  }
}
</script>
