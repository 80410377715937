<template>

  <div class="banner s-padding--xs s-hide s-expand-mobile" id="cookieConsent">
    <div class="s-swatch-primary s-box s-padding--s s-text-size--s">

      <div class="s-margin-bottom--s">Spell uses cookies to provide necessary site functionality and improve your experience. By using our website, you agree to our <router-link to="/cookies" class="s-text--bold--underline">cookie policy</router-link>.</div>
      <a class="s-swatch-default s-button-spacing--xs s-blockWidth--6 s-shape-pill" href="javascript:void(0)" @click="closeCookieConsent()">Close</a>

    </div>
  </div>

</template>
<script>
export default {
  mounted () {
    // Cookies

    if (!localStorage.getItem('cookieConsent')) {
      document.getElementById('cookieConsent').style.display = 'block'
      if (document.getElementById('cookieConsent')) {
        document.getElementById('cookieConsent').style.display = 'block'
      }
    }
  },
  methods: {
    closeCookieConsent () {
      localStorage.setItem('cookieConsent', 'agreed')
      if (document.getElementById('cookieConsent')) {
        document.getElementById('cookieConsent').style.display = 'none'
      }
    }
  }
}
</script>
