import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/spell.less'
import Modal from './components/modal.vue'
import modalVideo from './components/modalVideo.vue'
import VueSmoothScroll from 'vue3-smooth-scroll'
import { YoutubeVue3 } from 'youtube-vue3'
import mitt from 'mitt'

const emitter = mitt()
const app = createApp(App)

app.component('YoutubeVue3', YoutubeVue3)
app.config.globalProperties = {
  emitter: emitter
}
app.use(store).use(router).mount('#app')
app.use(VueSmoothScroll, {
  updateHistory: false,
  offset: -69
})
app.component('Modal', Modal)
app.component('modalVideo', modalVideo)
app.mixin({
  methods: {
    showModalVideo (visible) {
      this.emitter.emit('show-modal-video', visible)
    },
    showModalProblem (visible) {
      this.emitter.emit('show-modal-problem', visible)
    }
  }
})

router.afterEach((to) => {
  if (typeof ga === 'function') {
    window.ga.getAll()[0].set('page', to.path)
    window.ga.getAll()[0].send('pageview')
  }
})
